import React, { useEffect, useRef, useState } from 'react'
import { navigate } from 'gatsby-link'

import EditionBottomBar from '~components/Edition/EditionBottomBar/EditionBottomBar'
import { useGlobalContext } from '~contexts/globalContext'
import { getUrlParams } from '~utils/misc'
import EditorPageSidebar from './EditorPageSidebar/EditorPageSidebar'

import {
	ConfirmationModalProvider,
	useConfirmationModalContext
} from '~components/Common/Modal/ConfirmationModal/ConfirmationModal'

import './EditorPage.scss'

const EditorPage = () => {
	const [mobileView, setMobileView] = useState(false)

	const globalContext = useGlobalContext()

	const iframeRef = useRef<HTMLIFrameElement>()
	const confirmationModal = useConfirmationModalContext()

	const [modified, setModified] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [sidebarIsOpen, setSideBarIsOpen] = useState(false)
	const [currentPageMetadata, setCurrentPageMetadata] = useState({})
	const [page, setPage] = useState<any>()

	const changeEditMode = (editMode: boolean) => {
		if (editMode) {
			postIframeMessage('ENABLE_EDIT_MODE')
		} else {
			postIframeMessage('DISABLE_EDIT_MODE')
		}
	}

	const postIframeMessage = (type, payload = null) => {
		iframeRef.current.contentWindow.postMessage(
			{
				type,
				payload
			},
			document.location.origin
		)
	}

	useEffect(() => {
		document.addEventListener('keydown', (e) => {
			if (e.ctrlKey && e.key === 's') {
				e.preventDefault()
				handleSave()
			}
		})
	}, [])

	useEffect(() => {
		if (modified) {
			window.onbeforeunload = () => {
				return 'Les modifications que vous avez apportées ne seront peut-être pas enregistrées.'
			}

			return () => {
				window.onbeforeunload = null
			}
		}
	}, [modified])

	const handleSave = () => {
		postIframeMessage('SAVE')
	}

	useEffect(() => {
		const page = globalContext.website.getPage(getUrlParams().p)

		if (!page) return

		iframeRef.current.src = `${page.type === 'custom' ? '/p' : ''}/${page.slug}`
		setPage(page)
	}, [])

	useEffect(() => {
		const handleMessage = (e) => {
			if (e.origin === document.location.origin) {
				if (e.data.type === 'MODIFIED') {
					setModified(true)
				} else if (e.data.type === 'LOADING_STARTS') {
					setModified(true)
					setIsLoading(true)
				} else if (e.data.type === 'LOADING_ENDS') {
					setIsLoading(false)
				} else if (e.data.type === 'SAVED') {
					setModified(false)
				} else if (e.data.type === 'HELLO') {
					changeEditMode(true)
					setCurrentPageMetadata(e.data.payload?.metadata)
				}
			}
		}

		window.addEventListener('message', handleMessage)

		return () => {
			window.removeEventListener('message', handleMessage)
		}
	}, [])

	useEffect(() => {
		const handleMessage = (e) => {
			if (e.origin === document.location.origin) {
				if (e.data.type === 'GOTO') {
					if (!e.data?.payload?.url) return

					if (modified) {
						confirmationModal?.open({
							title:
								'Confirmez-vous vouloir quitter sans enregistrer les modifications ?',
							description:
								'Les modifications non enregistrées seront supprimées.',
							acceptCallback: () => {
								navigate(e.data.payload.url)
							},
							theme: 'danger',
							acceptText: 'Quitter sans sauvegarder',
							refuseText: 'Annuler'
						})
					} else {
						if (e.data?.payload?.url) navigate(e.data.payload.url)
					}
				}
			}
		}

		window.addEventListener('message', handleMessage)

		return () => {
			window.removeEventListener('message', handleMessage)
		}
	}, [modified])

	return (
		<div className="EditorPage">
			<EditionBottomBar
				postIframeMessage={postIframeMessage}
				modified={modified}
				isLoading={isLoading}
				handleSave={handleSave}
				{...{ mobileView, setMobileView }}
				handleOpenPanel={() => {
					setSideBarIsOpen(!sidebarIsOpen)
				}}
				panelIsOpen={sidebarIsOpen}
				page={page}
			/>
			<div className="content">
				<div className={`iframe-container ${mobileView ? 'mobile-view' : ''}`}>
					<iframe
						ref={iframeRef}
						// src="/"
						className="editor"
						style={
							mobileView
								? {
										height: 612,
										width: 375
								  }
								: {
										height: '100%',
										width: '100%'
								  }
						}
					></iframe>
				</div>
				<EditorPageSidebar
					active={sidebarIsOpen}
					data={currentPageMetadata}
					onChange={(metadata) => {
						postIframeMessage('METADATA_UPDATE', metadata)
						setCurrentPageMetadata(metadata)
					}}
				/>
			</div>
		</div>
	)
}

const EditorPageContainer = () => (
	<ConfirmationModalProvider>
		<EditorPage />
	</ConfirmationModalProvider>
)

export default EditorPageContainer
