import React, { useRef } from 'react'

import './EditorPageSidebar.scss'

const EditorPageSidebar = ({ active, data, onChange }) => {
	const textareaRef = useRef<HTMLTextAreaElement>()

	return (
		<div className={`EditorPageSidebar ${active ? 'active' : ''}`}>
			<h2>Paramètres de la page</h2>

			<h3>Indexation Google</h3>

			<label>
				<input
					type="checkbox"
					checked={data.indexed}
					onChange={() => {
						onChange({
							...data,
							indexed: !data.indexed
						})
					}}
				/>{' '}
				Page référencée
			</label>

			{data.indexed && (
				<>
					<h3>Metadata</h3>

					<input
						type="text"
						placeholder="title"
						defaultValue={data.title}
						onChange={(e) => {
							onChange({
								...data,
								title: e.target.value
							})
						}}
					/>
					<span
						className={`char-count ${
							data.title?.length > 49 && data.title?.length < 61 ? 'good' : ''
						}`}
					>
						{data.title?.length || 0} caractères (conseillé: 50-60 caractères)
					</span>

					<textarea
						ref={textareaRef}
						placeholder="description"
						defaultValue={data.description}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								e.preventDefault()
							}
						}}
						onChange={(e) => {
							onChange({
								...data,
								description: e.target.value.replace(/\n/g, '')
							})

							textareaRef.current.style.height = '0px'
							textareaRef.current.style.height = `${
								textareaRef.current.scrollHeight + 2
							}px`
						}}
					/>
					<span
						className={`char-count ${
							data.description?.length > 119 && data.description?.length < 161
								? 'good'
								: ''
						}`}
					>
						{data.description?.length || 0} caractères (conseillé: 120-160
						caractères)
					</span>
				</>
			)}

			<h3>Données de partage</h3>

			<input
				type="text"
				placeholder="og:title"
				defaultValue={data.ogTitle}
				onChange={(e) => {
					onChange({
						...data,
						ogTitle: e.target.value
					})
				}}
			/>
			<input
				type="text"
				placeholder="og:description"
				defaultValue={data.ogDescription}
				onChange={(e) => {
					onChange({
						...data,
						ogDescription: e.target.value
					})
				}}
			/>
			<input
				type="text"
				placeholder="og:image"
				defaultValue={data.ogImage}
				onChange={(e) => {
					onChange({
						...data,
						ogImage: e.target.value
					})
				}}
			/>
		</div>
	)
}

export default EditorPageSidebar
