import React from 'react'

const EditionBottomBarSmartPhoneIcon = () => (
	<svg
		width="18"
		height="30"
		viewBox="0 0 18 30"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M15.6873 0H2.31242C1.19813 0 0.286133 0.911143 0.286133 2.02629V27.9729C0.286133 29.088 1.19813 30 2.31242 30H15.6873C16.8016 30 17.7136 29.0897 17.7136 27.9737V2.02629C17.7136 0.911143 16.8016 0 15.6873 0ZM6.86042 1.46057H11.1393C11.2747 1.46057 11.3844 1.57029 11.3844 1.70657C11.3844 1.842 11.2747 1.95171 11.1393 1.95171H6.86042C6.72499 1.95171 6.61528 1.842 6.61528 1.70657C6.61528 1.57029 6.72499 1.46057 6.86042 1.46057ZM8.99985 28.9869C8.44013 28.9869 7.98671 28.5334 7.98671 27.9729C7.98671 27.4123 8.44013 26.9597 8.99985 26.9597C9.55956 26.9597 10.013 27.4123 10.013 27.9729C10.013 28.5334 9.55956 28.9869 8.99985 28.9869ZM16.3036 26.25H1.69613V3.21343H16.3036V26.25Z"
			fill="white"
		/>
	</svg>
)
export default EditionBottomBarSmartPhoneIcon
