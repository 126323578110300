import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'

import useWebsite from '~hooks/useWebsite'

import EditionBottomBarSmartPhoneIcon from './icons/EditionBottomBarSmartPhoneIcon'
import { useConfirmationModalContext } from '~components/Common/Modal/ConfirmationModal/ConfirmationModal'

import './EditionBottomBar.scss'

const EditionBottomBar: TEditionBottomBarComponent = ({
	modified,
	isLoading,
	handleSave,
	mobileView,
	setMobileView,
	page
}) => {
	const [isSaving, setIsSaving] = useState(false)
	const [enabled, setEnabled] = useState(true)

	const website = useWebsite()
	useEffect(() => {
		setEnabled(page?.enabled)
	}, [page])
	useEffect(() => {
		setIsSaving(false)
	}, [modified])

	const confirmationModal = useConfirmationModalContext()

	return (
		<div className="EditionBottomBar">
			<div>
				<button
					onClick={() => {
						if (modified) {
							confirmationModal?.open({
								title:
									'Confirmez-vous vouloir quitter sans enregistrer les modifications ?',
								description:
									'Les modifications non enregistrées seront supprimées.',
								acceptCallback: () => {
									navigate('/admin')
								},
								theme: 'danger',
								acceptText: 'Quitter sans sauvegarder',
								refuseText: 'Annuler'
							})
						} else {
							navigate('/admin')
						}
					}}
				>
					Retour
				</button>
				<button
					disabled={!modified || isLoading}
					onClick={() => {
						setIsSaving(true)
						handleSave()
					}}
				>
					{isSaving ? (
						'...'
					) : isLoading ? (
						<i className="mdi mdi-spin mdi-loading" />
					) : (
						<strong>Enregistrer</strong>
					)}
				</button>
			</div>

			{!enabled && (
				<div className="activate_div">
					<button
						className="activate"
						onClick={() => {
							setEnabled(true)
							website.updatePage({
								id: page.id,
								enabled: true
							})
						}}
					>
						Activer la page
					</button>
				</div>
			)}

			<div>
				<button
					onClick={() => {
						window.open(`/${page.slug}`)
					}}
				>
					Voir comme un visiteur
				</button>
				<button
					className={`${mobileView ? 'active' : ''}`}
					onClick={() => {
						setMobileView(!mobileView)
					}}
				>
					<EditionBottomBarSmartPhoneIcon />
				</button>
				{/* <button
					onClick={() => {
						handleOpenPanel()
					}}
					className={`menu-button ${panelIsOpen ? 'active' : ''}`}
				>
					<EditionBottomBarSmartCogIcon />
				</button> */}
			</div>
		</div>
	)
}

export default EditionBottomBar

type TEditionBottomBarComponent = React.FC<{
	modified: boolean
	isLoading: boolean
	handleSave: () => void
	mobileView: boolean
	setMobileView: (mobileView: boolean) => void
	page: any
	handleOpenPanel: (panelIsOpen: boolean) => void
	panelIsOpen: boolean
	postIframeMessage: (type: any, payload: any) => void
}>
